<template>
    <div class="all-page">
        <TopNav title="平台币" backPath="Mine" goPageName="订单记录" goPath="OrderList"></TopNav>
        <div class="all-page-content-box">
        <div class="balance-box">
            <div class="line"></div>平台币余额：<span>{{$store.state.platformCoin}}</span>
        </div>
        <div class="recharge-platform-box">
            <div class="title">
                充值平台币
            </div>
            <div class="number-list">
                <div class="number-list-item-box" v-for="(item, index) in numberList" :key="index"
                    @click="getRechargeNumber(item)">
                    <div :class="rechargeNumber===item?'number-list-item-s':'number-list-item'">
                        {{item}}
                    </div>
                </div>
            </div>
            <div class="custom-number" @click="openCustomInput" v-show="!isShowNumberInput">
                自定义金额
            </div>
            <div class="input-box-h" v-show="isShowNumberInput">
                <input ref="nInput" v-model="customNumber" @input="clearNan" type="tel" placeholder="请输入金额" class="input-h">
            </div>
        </div>
        <div class="pay-radio-box">
            <div class="title">
                {{payMethodsList.length>0?'选择支付方式':'无可用支付方式'}}
            </div>
            <div class="pay-methods-list">
                <div class="pay-methods-item" v-for="(item, index) in payMethodsList" :key="index"
                    @click="getPayMethods(item)">
                    <div class="methods-box">
                        <img :src="item.icon" :class="item.pay_way_id===payMethods?'':'pay-icon-no'">
                        <span :class="item.pay_way_id===payMethods?'':'pay-text-no'">{{item.name}}</span>
                    </div>
                    <img src="@/assets/pay/radio_select.svg" class="radio-check" v-show="item.pay_way_id===payMethods">
                    <div class="radio-box-nocheck" v-show="item.pay_way_id!==payMethods"></div>
                </div>
            </div>
            <div class="btn-box" :class="payMethodsList.length>0?'':'btn-box-no-methods'" @click="pay">
                确 认
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"

    

    export default {
        components: {
            TopNav
        },
        props: {
            payMethodsList:Array,
            numberList:Array,
            isShowNumberInput:Boolean,
            payMethods:Number,
            rechargeNumber:Number
        },
        data() {
            return {
                customNumber:null
            }
        },
        methods: {
            clearNan() {
                this.customNumber = this.customNumber.replace(/\D/g,"")
            },
            getRechargeNumber(num) {
                this.$emit('getRechargeNumber', num)
            },
            pay() {
                this.$emit('openPayWindow', this.customNumber)
            },
            getPayMethods(item) {
                this.$emit('getPayMethods', item)
            },
            openCustomInput() {
                this.$emit('openCustomInput', this)
            },
            clearCustomNumber() {
                this.customNumber = null
            }
        }
    }
</script>

<style scoped>
    .balance-box {
        font-size: 8.75px;
        font-weight: 400;
        color: #666666;
        display: flex;
        align-items: center;
    }
    .line {
        margin-right: 2px;
        height: 8.125px;
        width: 2px;
        background-color: #16C5CE;
        border-radius: 2px;
    }
    .balance-box>span {
        font-size: 12.5px;
        font-weight: 500;
        color: #16C5CE;
    }

    .recharge-platform-box {
        padding: 6.25px 0 8.75px 0;
    }

    .title {
        padding-bottom: 6px;
        font-size: 10px;
        font-weight: 500;
        color: #333333;
    }

    .pay-methods-list {
        padding-bottom: 7.5px;
    }

    .number-list,
    .pay-methods-list,
    .pay-methods-item,
    .methods-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .number-list-item-box {
        padding-bottom: 7.2px;
        width: 30%;
    }

    .number-list-item,
    .number-list-item-s,
    .custom-number {
        height: 21.25px !important;
        border-radius: 2px;
        font-size: 8.75px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .number-list-item {
        background: #F5F7F7;
        color: #333333;
    }

    .number-list-item-s {
        background: #16C5CE;
        color: #ffffff;
    }

    .custom-number {
        margin-top: 1px;
        width: 100%;
        height: 21.25px;
        background: #F5F7F7
    }


    .pay-methods-item {
        height: 28.7px;
        width: 47%;
        background-color: #F5F7F7;
        padding: 0 7.5px;
        margin-bottom: 8.75px;
    }
    
    .methods-box {
        flex-wrap: nowrap;
        flex: 1;
        justify-content: flex-start;
        overflow: hidden;
    }

    .methods-box>img {
        width: 17px;
    }

    .pay-icon-no,
    .pay-text-no {
        opacity: 0.5;
    }

    .methods-box>span {
        padding-left: 4px;
        font-size: 8.75px;
        font-weight: 400;
        color: #333333;
        white-space: nowrap;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .radio-check,
    .radio-box-nocheck {
        width: 10px;
    }

    .radio-box-nocheck {
        height: 10px;
        border-radius: 50%;
        background: rgba(204, 204, 204, 0.39);
    }
    .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 26.25px;
        background: #16C5CE;
        border-radius: 4px;
        font-size: 10px;
        font-weight: 400;
        color: #FFFFFF;
    }
</style>
