<template>
    <div>
        <Horizontal ref="platfomCoin" v-if="$store.getters.currentPageVersion === 'Horizontal'" 
        :numberList="numberList"
        :rechargeNumber="rechargeNumber"
        :isShowNumberInput="isShowNumberInput"
        :payMethodsList="payMethodsList"
        :payMethods="payMethods"
        @getRechargeNumber="getRechargeNumber"
        @openPayWindow="openPayWindow"
        @getPayMethods="getPayMethods"
        @openCustomInput="openCustomInput"></Horizontal>
        <Vertical ref="platfomCoin" v-else
        :numberList="numberList"
        :payMethods="payMethods"
        :isShowNumberInput="isShowNumberInput"
        :payMethodsList="payMethodsList"
        :rechargeNumber="rechargeNumber"
        @getRechargeNumber="getRechargeNumber"
        @openPayWindow="openPayWindow"
        @getPayMethods="getPayMethods"
        @openCustomInput="openCustomInput"></Vertical>
    </div>
    
</template>

<script>
    import Horizontal from '@/components/Mine/PlatformCoin/Horizontal/Index.vue'
    import Vertical from '@/components/Mine/PlatformCoin/Vertical/Index.vue'
    
    import alipayIcon from '@/assets/pay/icon_alipay.svg'
    import wechatpayIcon from '@/assets/pay/icon_wechat.svg'
    
    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
                numberList: [20, 50, 100, 200, 500, 1000],
                rechargeNumber: 20,//勾选的非自定义金额
                selectNumber:20,//最终要提交的金额
                isShowNumberInput: false,
                payMethodsList: [],
                payMethods: 0,
                payMethodCode:null
            }
        },
        created() {
            
            this.$store.dispatch('getPlatFormCoinBalance', this)
            this.getPayMethodsList()  
        },
        methods:{
            //点击面板的数字
            getRechargeNumber(num) {
                 console.log(num)
                this.rechargeNumber = num
                this.isShowNumberInput = false
                this.selectNumber = num
                this.$refs.platfomCoin.clearCustomNumber()
            },
            //点击确认
            openPayWindow(customNumber) {
                if(this.isShowNumberInput) {
                    this.selectNumber = customNumber
                }
                if(!this.selectNumber) {
                    this.$Toast({
                    	message: '金额不正确',
                    })
                    return
                }
                console.log(this.selectNumber)
                console.log(this.payMethods)
                let requestData = {}
                requestData.user_id = this.$store.state.userId
                requestData.token = this.$store.state.token
                requestData.order_money = this.selectNumber*100
                requestData.pay_way_id = this.payMethods
                requestData.return_url =  this.$common.getPayReturnUrl()
                this.$api.pay.createOrderForPlatfomCoin(requestData).then(res=> {
                    if(res.data.code===200) {
                        let data = res.data.data,
                            url = data.data,
                            pullType = data.type,
                            nativeDataPack = {
                                url,
                                pullType,
                                payType: this.payMethodCode
                            }
                        switch (this.$common.getNowSystem()) {
                        	case 'ios':
                        		this.$common.postMessageToIos('nativePayment', JSON.stringify(nativeDataPack))
                                return
                        	case 'Android':
                                console.log('Android')
                                console.log(nativeDataPack)
                        		android.nativePayment(pullType,this.payMethodCode,url)
                        		break;
                        	default:
                        		this.$router.push({
                        		    name: 'WebPayPage',
                        		    params: nativeDataPack
                        		})
                        		break;
                        }
                    }
                })
            },
            getPayMethods(e) {
                this.payMethods = e.pay_way_id
                if(e.pay_way_template_code === 'alipay') {
                     this.payMethodCode = 0
                }else if(e.pay_way_template_code === 'wechat') {
                    this.payMethodCode = 1
                }
               
            },
            //获取支付通道
            getPayMethodsList() {
                 this.$api.pay.getPayMethods({}).then(res=> {
                     console.log(res)
                     if(res.data.code === 200) {
                         this.payMethodsList = res.data.data
                         this.payMethodsList.forEach(item=> {
                             if(item.pay_way_template_code === 'alipay') {
                                 item.icon = alipayIcon
                             }else if(item.pay_way_template_code === 'wechat') {
                                 item.icon = wechatpayIcon
                             }else if(item.pay_way_template_code === 'qzl_wechat') {
                                 //处理趣智联
                                 item.icon = wechatpayIcon
                             }else if(item.pay_way_template_code === 'qzl_alipay') {
                                 item.icon = alipayIcon
                             }
                         })
                         console.log(this.payMethodsList[0].pay_way_id)                         
                         this.payMethods = this.payMethodsList[0].pay_way_id
                         if(this.payMethodsList[0].pay_way_template_code === 'alipay') {
                              this.payMethodCode = 0
                         }else if(this.payMethodsList[0].pay_way_template_code === 'wechat') {
                             this.payMethodCode = 1
                         }
                     }
                 })
            },
            //使用自定义金额
            openCustomInput(that) {
                this.isShowNumberInput = true
                this.rechargeNumber = 0
                this.$nextTick(()=> {
                    that.$refs.nInput.focus()
                })
            },
            
        }
    }
</script>

<style scoped>
    
</style>